/** @format */

const ModalError = [
    '49 49',
    `<title>Modal Error</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="83" height="83"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="menu-/-close_big" transform="translate(-17.000000, -17.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="menu-/-close_big-(Background/Mask)"></g>
                <path d="M60.8320839,17.2916667 L41.5,36.6237505 L22.1679166,17.2916667 L17.2916667,22.1679166 L36.6237505,41.5 L17.2916667,60.8320839 L22.1679166,65.7083333 L41.5,46.3762495 L60.8320839,65.7083333 L65.7083333,60.8320839 L46.3762495,41.5 L65.7083333,22.1679166 L60.8320839,17.2916667 Z" fill="var(--ci-primary-color, currentColor)" fill-rule="nonzero" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>`
];

export default ModalError;