/** @format */

import React from 'react';
import classnames from 'classnames';
import { LoadingProps } from './type';

const Loading: React.FC<LoadingProps> = ({
    relative,
}: LoadingProps) => (
    <div
        className={classnames({
            'pt-3 text-center position-absolute d-flex align-items-center justify-content-center w-100': true,
            'h-100': !relative,
            't-60': relative,
        })}>
        <div className='sk-fold'>
            <div className='sk-fold-cube' />
            <div className='sk-fold-cube' />
            <div className='sk-fold-cube' />
            <div className='sk-fold-cube' />
        </div>
    </div>
);

export default Loading;
