/** @format */

const Selected = [
    '10 18',
    `<title>Selected </title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="arrow-/-chevron_big_right" transform="translate(-7.000000, -3.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="arrow-/-chevron_big_right-(Background/Mask)"></g>
                <path d="M15.5354946,3.51466517 L7.05021265,11.9999471 L15.5354936,20.4852291 L16.9497078,19.071014 L9.87864068,11.9999481 L16.9497078,4.92887859 L15.5354946,3.51466517 Z" fill="var(--ci-primary-color, currentColor)" fill-rule="nonzero" mask="url(#mask-2)" transform="translate(11.999960, 11.999947) rotate(-179.999995) translate(-11.999960, -11.999947) "></path>
            </g>
        </g>
    </g>`
];

export default Selected;
