/** @format */

const Billing = [
    '20 20',
    `<title>Billing</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Billing" fill="var(--ci-primary-color, currentColor)" fill-rule="nonzero">
            <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10.88,15.76 L10.88,16.12 C10.88,16.6 10.49,17 10,17 L10,17 C9.52,17 9.12,16.61 9.12,16.12 L9.12,15.7 C8.49,15.55 7.19,15.09 6.43,13.6 C6.2,13.16 6.42,12.61 6.88,12.42 L6.95,12.39 C7.36,12.22 7.82,12.39 8.03,12.78 C8.35,13.39 8.98,14.15 10.15,14.15 C11.08,14.15 12.13,13.67 12.13,12.54 C12.13,11.58 11.43,11.08 9.85,10.51 C8.75,10.12 6.5,9.48 6.5,7.2 C6.5,7.1 6.51,4.8 9.12,4.24 L9.12,3.88 C9.12,3.39 9.52,3 10,3 L10,3 C10.48,3 10.88,3.39 10.88,3.88 L10.88,4.25 C11.95,4.44 12.63,5.01 13.04,5.55 C13.38,5.99 13.2,6.63 12.68,6.85 L12.68,6.85 C12.32,7 11.9,6.88 11.66,6.57 C11.38,6.19 10.88,5.8 10.06,5.8 C9.36,5.8 8.25,6.17 8.25,7.19 C8.25,8.14 9.11,8.5 10.89,9.09 C13.29,9.92 13.9,11.14 13.9,12.54 C13.9,15.17 11.4,15.67 10.88,15.76 Z" id="Shape"></path>
        </g>
    </g>
    `
];
export default Billing;