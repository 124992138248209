/** @format */


const Dashboard = [
    '19 16',
    `
    <title>Dashboard</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="grid-/-dashboard_02" transform="translate(-3.000000, -4.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="grid-/-dashboard_02-(Background/Mask)"></g>
                <path d="M3,11 L3,4 L22,4 L22,11 L3,11 L3,11 Z M3,20 L9,20 L9,13 L3,13 L3,20 L3,20 Z M11,20 L22,20 L22,13 L11,13 L11,20 L11,20 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
    `
];

export default Dashboard;