/** @format */

import {
    createStore,
    applyMiddleware,
    // compose
} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

const middleware = [thunk];

const persistConfig = {
    key: 'ASSYST_KIBONO_root',
    storage,
    debug: false,
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
    const store = createStore(
        persistedReducer,
        applyMiddleware(...middleware),
    );
    const persistor = persistStore(store);
    return {
        store,
        persistor,
    };
};
