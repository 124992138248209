/** @format */

import { AdminBillingState } from './type';

const InitialAdminBillingState: AdminBillingState = {
    history: [],
    summary: {},
};

export default InitialAdminBillingState;
