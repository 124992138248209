/** @format */

import { SET_TOKEN } from '../actionTypes';
import InitialAuthState from './state';

export default (state: any = InitialAuthState, action: any) => {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, ...action.payload };

        default:
            return { ...state };
    }
};
