/** @format */

const Dropdown = [
    '10 6',
    `<title>Dropdown</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="arrow-/-caret_down" transform="translate(-7.000000, -9.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="arrow-/-caret_down-(Background/Mask)"></g>
                <path d="M9.49999989,11.9999998 L14.4999999,16.9999998 L14.4999999,6.99999978 L9.49999989,11.9999998 Z" fill="var(--ci-primary-color, currentColor)" fill-rule="nonzero" mask="url(#mask-2)" transform="translate(12.000000, 12.000000) rotate(-90.000003) translate(-12.000000, -12.000000) "></path>
            </g>
        </g>
    </g>
    `
];

export default Dropdown;
