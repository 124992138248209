/** @format */

const ModalWarning = [
    '71 71',
    `<title>Modal Warning</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="83" height="83"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="attention-/-error" transform="translate(-6.000000, -6.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="attention-/-error-(Background/Mask)"></g>
                <path d="M41.5,6.91666667 C22.4100001,6.91666667 6.91666667,22.4100001 6.91666667,41.5 C6.91666667,60.5900016 22.4100001,76.0833333 41.5,76.0833333 C60.5900016,76.0833333 76.0833333,60.5900016 76.0833333,41.5 C76.0833333,22.4100001 60.5900016,6.91666667 41.5,6.91666667 C28.7733334,6.91666667 28.7733334,6.91666667 41.5,6.91666667 Z M38.0416667,58.7916667 L38.0416667,51.875 L44.9583333,51.875 L44.9583333,58.7916667 L38.0416667,58.7916667 L38.0416667,58.7916667 Z M38.0416667,24.2083333 L38.0416667,44.9583333 L44.9583333,44.9583333 L44.9583333,24.2083333 L38.0416667,24.2083333 L38.0416667,24.2083333 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
`
];

export default ModalWarning;
