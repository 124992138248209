/** @format */

import InitialEmployeeBusinessState from './state';

export default (state: any = InitialEmployeeBusinessState, action: any) => {
    switch (action.type) {
        default:
            return { ...state };
    }
};
