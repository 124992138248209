/** @format */

import { combineReducers } from 'redux';
import adminBilling from './adminBilling/reducer';
import auth from './auth/reducer';
import setting from './settings/reducer';
import employerBusiness from './employerBusiness/reducer';
import employeeBusiness from './employee/reducer';


const reducers = combineReducers({
    auth,
    adminBilling,
    setting,
    employerBusiness,
    employeeBusiness
});

export default reducers;
