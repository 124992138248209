/** @format */

import { TOGGLE_SIDEBAR } from '../actionTypes';
import InitialSettingState from './state';

export default (state: any = InitialSettingState, action: any) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, sidebarShow: action.payload };

        default:
            return { ...state };
    }
};
