/** @format */

const Claims = [
    '16 20',
    `
    <title>Claims</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Claims" transform="translate(-4.000000, -2.000000)">
            <g id="file-/-file_new">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="file-/-file_new-(Background/Mask)"></g>
                <path d="M19.9276457,8.65195513 C19.9306927,8.6612339 19.9337597,8.67057371 19.9370003,8.68000031 C19.9650002,8.76400042 19.9819994,8.8499999 19.9869995,8.93799973 C19.9879198,8.9481287 19.9907484,8.95762157 19.9935331,8.96696663 C19.9967976,8.9779191 20,8.98866844 20,9 L20,20 C20,21.1030006 19.1029997,22 18,22 L6,22 C4.89699996,22 4,21.1030006 4,20 L4,4 C4,2.89699996 4.89699996,2 6,2 L13,2 C13.0113316,2 13.0220804,2.00320283 13.0330334,2.00646624 C13.0423784,2.00925067 13.0518713,2.01207921 13.0620003,2.01300001 C13.151,2.01800001 13.2370005,2.03599989 13.3210001,2.06399989 C13.3309698,2.06742716 13.3409405,2.0705632 13.3508501,2.07368022 C13.3727465,2.08056746 13.3943472,2.08736186 13.415,2.09699988 C13.5209999,2.14599988 13.6219997,2.20699999 13.7080002,2.29299998 L19.7080002,8.29300022 C19.7940006,8.37900019 19.8549995,8.4800005 19.9039993,8.58600044 C19.9131699,8.60786819 19.9203501,8.62973595 19.9276457,8.65195513 C19.929677,8.65814098 19.929677,8.65814098 19.9276457,8.65195513 Z M10.5283203,15.8899937 L15.1283207,11.2899933 L16.5383205,12.6999931 L10.5283203,18.7099934 L7.02832031,15.2099934 L8.43832016,13.7999935 L10.5283203,15.8899937 Z M14,8 L16.5860004,8 L14,5.41400003 L14,8 L14,8 Z M6,4 L6,20 L18.0020008,20 L18,10 L13,10 C12.4470005,10 12,9.55299997 12,9 L12,4 L6,4 L6,4 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
    `
];

export default Claims;
