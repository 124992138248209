/** @format */

import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from '../components/common/loading';
import configureStore from '../redux/store';
import { stripeConfig } from '../constants';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(`${stripeConfig.public_key}`);

const AuthPages = lazy(
    () => import(/* webpackChunkName: "AuthLayout" */ './auth'),
);
const InternalPages = lazy(
    () =>
        import(
            /* webpackChunkName: "AuthLayout" */ '../containers/InternalLayout'
        ),
);
const StatusPage = lazy(
    () =>
        import(
            /* webpackChunkName: "StatusPages" */ '../containers/StatusPage'
        ),
);
const Store = configureStore();
const AppRenderer: React.FC = () => {
    return (
        <Provider store={Store.store}>
            <Elements stripe={stripePromise}>
                {/* 
                // @ts-ignore */}
                <PersistGate
                    loading={<Loading />}
                    persistor={Store.persistor}>
                    <Suspense fallback={<Loading />}>
                        {/* Localization */}
                        {/* Notification */}
                        <BrowserRouter>
                            <Switch>
                                <Route exact path='/403'>
                                    <StatusPage status='403' />
                                </Route>
                                <Route exact path='/404'>
                                    <StatusPage status='404' />
                                </Route>
                                <Route exact path='/500'>
                                    <StatusPage status='500' />
                                </Route>
                                <Route path='/app'>
                                    <InternalPages />
                                </Route>
                                <Route path='/'>
                                    <AuthPages />
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </Suspense>
                </PersistGate>
            </Elements>
        </Provider>
    );
};
export default AppRenderer;
