/** @format */

import InitialEmployerBusinessState from './state';

export default (state: any = InitialEmployerBusinessState, action: any) => {
    switch (action.type) {
        default:
            return { ...state };
    }
};
