/** @format */

const SwitchAccounts = [
    '20 16',
    `<title>Switch Accounts</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="system-/-transfer" transform="translate(-2.000000, -4.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="system-/-transfer-(Background/Mask)"></g>
                <path d="M20,8 L15,4 L15,6.99900007 L2,6.99900007 L2,8.9989996 L15,8.9989996 L15,12 L20,8 Z M9,15 L9,12 L4,16 L9,20 L9,17 L22,17 L22,15 L9,15 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>`
];

export default SwitchAccounts;