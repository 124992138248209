/** @format */

const DateRange = [
    '18 20',
    `
    <title>Date Range</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="calendar-/-calendar_event" transform="translate(-3.000000, -2.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="calendar-/-calendar_event-(Background/Mask)"></g>
                <path d="M7,12 L7,18 L13,18 L13,12 L7,12 Z M17,4 L19,4 C20.1030006,4 21,4.89699984 21,6 L21,20 C21,21.1030006 20.1030006,22 19,22 L5,22 C3.89699984,22 3,21.1030006 3,20 L3,6 C3,4.89699984 3.89699984,4 5,4 L7,4 L7,2 L9,2 L9,4 L15,4 L15,2 L17,2 L17,4 L17,4 Z M5,20 L19.0009995,20 L19.0002861,10 L5,10 L5,20 L5,20 Z M5,8 L19,8 L19,6 L5,6 L5,8 L5,8 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
    `
];

export default DateRange;