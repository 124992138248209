/** @format */

const Logout = [
    '18 18',
    `<title>Logout</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="basic-/-log_out" transform="translate(9.000000, 9.000000) rotate(-180.000000) translate(-9.000000, -9.000000) translate(-3.000000, -3.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="basic-/-log_out-(Background/Mask)"></g>
                <path d="M17,12 L12,8 L12,11 L3,11 L3,13 L12,13 L12,16 L17,12 Z M10,3 C8.89699996,3 8,3.89699996 8,5 L8,9 L10,9 L10,5 L19,5 L19,19 L10,19 L10,15 L8,15 L8,19 C8,20.103 8.89699996,21 10,21 L19,21 C20.103,21 21,20.103 21,19 L21,5 C21,3.89699996 20.103,3 19,3 L10,3 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>`
];

export default Logout;
