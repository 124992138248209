/** @format */

const Employers = [
    '20 18',
    `<title>Company/Employers</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Company/Employers" transform="translate(-2.000000, -3.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M20,7 L16,7 L16,5 L14,3 L10,3 L8,5 L8,7 L4,7 C2.9,7 2,7.9 2,9 L2,14 C2,14.75 2.4,15.38 3,15.73 L3,19 C3,20.11 3.89,21 5,21 L19,21 C20.11,21 21,20.11 21,19 L21,15.72 C21.59,15.37 22,14.73 22,14 L22,9 C22,7.9 21.1,7 20,7 Z M10,5 L14,5 L14,7 L10,7 L10,5 Z M4,9 L20,9 L20,14 L15,14 L15,11 L9,11 L9,14 L4,14 L4,9 Z M13,15 L11,15 L11,13 L13,13 L13,15 Z M19,19 L5,19 L5,16 L9,16 L9,17 L15,17 L15,16 L19,16 L19,19 Z" id="Shape" fill="var(--ci-primary-color, currentColor)" fill-rule="nonzero"></path>
        </g>
    </g>
    `
];

export default Employers;
