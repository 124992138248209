/** @format */

import React from 'react';

const Setting = React.lazy(() => import('../../views/Setting'));
const AdminBilling = React.lazy(
    () => import('../../views/AdminBilling'),
);
const CompanyDetails = React.lazy(
    () => import('../../views/CompanyDetails'),
);
const ClaimDetails = React.lazy(
    () => import('../../views/Claims/ClaimDetails'),
);
const AdminClaims = React.lazy(() => import('../../views/Claims'));
const Employers = React.lazy(
    () => import('../../views/AdminEmployers'),
);
const Employer = React.lazy(
    () => import('../../views/Employers'),
);
const EmployerBusiness = React.lazy(
    () => import('../../views/MyBusiness'),
);
const EmployerStatements = React.lazy(
    () => import('../../views/Statements'),
);
const EmployeeStatements = React.lazy(
    () => import('../../views/Employee/EmployeeSatements'),
);


const Dashboard = React.lazy(
    () => import('../../views/DashBoard'),
);
// const EmployerEligibility = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility'),
// );
// const EmployerWizard = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/EmployerWizard'),
// );
// const EmployeeAddClass = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/AddEmployeeClasses'),
// );
// const EmployeeSummery = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/SetupSettings/index'),
// );
// const KibonoFunds = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/EmployerWizard/EmployeeSummery/KibonoAccountFund'),
// );
// const AccessPermission = React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/EmployerWizard/EmployerBusiness'),
// );
const EmployerDashboard = React.lazy(
    () => import('../../views/AdminEmployers/EmployerDashborad'),
);
// const EligibilitySteps= React.lazy(
//     () => import('../../views/AdminEmployers/EmployerEligibility/EmployerWizard/EmployerBusiness/EligibilitySteps'),
// );

const MyEmployee = React.lazy(
    () => import('../../views/Employee'),
);
const EmployeeDashboard = React.lazy(
    () => import('../../views/EmployeeDashboard'),
);
// const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
// const Tables = React.lazy(() => import('./views/base/tables/Tables'));

// const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));
// const Cards = React.lazy(() => import('./views/base/cards/Cards'));
// const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'));
// const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'));
// const BasicForms = React.lazy(() => import('./views/base/forms/BasicForms'));

// const Jumbotrons = React.lazy(() => import('./views/base/jumbotrons/Jumbotrons'));
// const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'));
// const Navbars = React.lazy(() => import('./views/base/navbars/Navbars'));
// const Navs = React.lazy(() => import('./views/base/navs/Navs'));
// const Paginations = React.lazy(() => import('./views/base/paginations/Pagnations'));
// const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'));
// const ProgressBar = React.lazy(() => import('./views/base/progress-bar/ProgressBar'));
// const Switches = React.lazy(() => import('./views/base/switches/Switches'));

// const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
// const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));
// const BrandButtons = React.lazy(() => import('./views/buttons/brand-buttons/BrandButtons'));
// const ButtonDropdowns = React.lazy(() => import('./views/buttons/button-dropdowns/ButtonDropdowns'));
// const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
// const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
// const Charts = React.lazy(() => import('./views/charts/Charts'));
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
// const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
// const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
// const Brands = React.lazy(() => import('./views/icons/brands/Brands'));
// const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
// const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
// const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
// const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
// const Typography = React.lazy(() => import('./views/theme/typography/Typography'));
// const Widgets = React.lazy(() => import('./views/widgets/Widgets'));
// const Users = React.lazy(() => import('./views/users/Users'));
// const User = React.lazy(() => import('./views/users/User'));

export const adminRoutes: any = [
    {
        path: '/app/claims/',
        exact: true,
        name: 'Admin Claims',
        component: AdminClaims,
    },
    {
        path: '/app/claims/claimDetails',
        exact: true,
        name: 'Admin Claims',
        component: ClaimDetails,
    },
    {
        path: '/app/employers',
        exact: true,
        name: 'Employers',
        component: Employers,
    },
    {
        path: '/app/employers/:id',
        exact: true,
        name: 'Admin Employer',
        component: Employer,
    },
    {
        path: '/app/billing',
        exact: true,
        name: 'Admin Billing',
        component: AdminBilling,
    },
    {
        path: '/app/billing/companyDetails/:id',
        exact: true,
        name: 'Admin Billing',
        component: CompanyDetails,
    },
    {
        path: '/app/settings',
        exact: true,
        name: 'Settings',
        component: Setting,
    },
];

export const employerRoutes1: any = [
    {
        path: '/app/employerDashboard',
        exact: true,
        name: 'EmployerDashboard',
        component: EmployerDashboard,
    },
    {
        path: '/app/dashboard',
        exact: true,
        name: 'Dashboard',
        component: Dashboard,
    },
];
export const employerRoutes2: any = [
    {
        path: '/app/myBusiness',
        exact: true,
        name: 'EmployerBusiness',
        component: EmployerBusiness,
    },
];
export const employerRoutes3: any = [
    {
        _tag: 'CSidebarNavItem',
        name: 'Statements',
        path: '/app/statements',
        component:EmployerStatements,
    },

    {
        path: '/app/statements',
        exact: true,
        name: 'MyEmployee',
        component: MyEmployee,
    },
];
export const employerRoutes4: any = [
    {
        path: '/app/settings',
        exact: true,
        name: 'Settings',
        component: Setting,
    },
];
export const employerRoutes: any = [...employerRoutes1, ...employerRoutes2, ...employerRoutes3, ...employerRoutes4];
export const employeeRoutes: any = [
    {
        path: '/app/settings',
        exact: true,
        name: 'Settings',
        component: Setting,
    },
    {
        path: '/app/my-dashboard',
        exact: true,
        name: 'Dashboard',
        component: EmployeeDashboard,
    },
    {
        path: '/app/my-statements',
        exact: true,
        name: 'Statements',
        component:EmployeeStatements ,
    },
];


const routes: any = [
    // { path: '/', exact: true, name: 'Home' },
    
    
    
    
    // {
    //     path: '/app/employeeStatement',  
    //     exact: true,
    //     name: 'Employee Statements',
    //     component: EmployeeStatements,
    // },
    
    

    // {
    //     path: '/app/employerEligibility',
    //     exact: true,
    //     name: ' EmployerEligibility',
    //     component: EmployerEligibility,
    // },
    // {
    //     path: '/app/employerWizard',
    //     exact: true,
    //     name: 'EmployerWizard',
    //     component: EmployerWizard,
    // },
    // {
    //     path: '/app/employeeAddOrUpdate',
    //     exact: true,
    //     name: 'EmployeeAddClass',
    //     component: EmployeeAddClass,
    // },
    // {
    //     path: '/app/employeeSummery/:id',
    //     exact: true,
    //     name: 'EmployeeSummery',
    //     component: EmployeeSummery,
    // },
    // {
    //     path: '/app/kibonoFunds',
    //     exact: true,
    //     name: 'KibonoFunds',
    //     component: KibonoFunds,
    // },
    // {
    //     path: '/app/accessPermission',
    //     exact: true,
    //     name: 'AccessPermission',
    //     component: AccessPermission,
    // },
    // {
    //     path: '/app/eligibilitySteps',
    //     exact: true,
    //     name: 'EligibilitySteps',
    //     component: EligibilitySteps,
    // },
    // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    // { path: '/theme', name: 'Theme', component: Colors, exact: true },
    // { path: '/theme/colors', name: 'Colors', component: Colors },
    // { path: '/theme/typography', name: 'Typography', component: Typography },
    // { path: '/base', name: 'Base', component: Cards, exact: true },
    // { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
    // { path: '/base/cards', name: 'Cards', component: Cards },
    // { path: '/base/carousels', name: 'Carousel', component: Carousels },
    // { path: '/base/collapses', name: 'Collapse', component: Collapses },
    // { path: '/base/forms', name: 'Forms', component: BasicForms },
    // { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
    // { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
    // { path: '/base/navbars', name: 'Navbars', component: Navbars },
    // { path: '/base/navs', name: 'Navs', component: Navs },
    // { path: '/base/paginations', name: 'Paginations', component: Paginations },
    // { path: '/base/popovers', name: 'Popovers', component: Popovers },
    // { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
    // { path: '/base/switches', name: 'Switches', component: Switches },
    // { path: '/base/tables', name: 'Tables', component: Tables },
    // { path: '/base/tabs', name: 'Tabs', component: Tabs },
    // { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
    // { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
    // { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
    // { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
    // { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
    // { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
    // { path: '/charts', name: 'Charts', component: Charts },
    // { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
    // { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
    // { path: '/icons/flags', name: 'Flags', component: Flags },
    // { path: '/icons/brands', name: 'Brands', component: Brands },
    // { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
    // { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
    // { path: '/notifications/badges', name: 'Badges', component: Badges },
    // { path: '/notifications/modals', name: 'Modals', component: Modals },
    // { path: '/notifications/toaster', name: 'Toaster', component: Toaster },
    // { path: '/widgets', name: 'Widgets', component: Widgets },
    // { path: '/users', exact: true, name: 'Users', component: Users },
    // { path: '/users/:id', exact: true, name: 'User Details', component: User }
];

export default routes;
