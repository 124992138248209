export const hoverOptions = {
    interactiveDebounce: 75,  
    moveTransition: 'transform 0.2s ease-out',
    allowHTML: true,
    // maxWidth:800,
    // maxHeight: 800,
    offset: [0, -50],
    delay: [500, 100],
    placement: 'auto',
    // strategy: 'fixed',
    modifier: [{
        name: 'computeStyles',
        enabled: true,
        fn({state}: any) {
            state.styles.popper = {
                ...state.styles.popper,
                position: 'fixed',
                height: '100vh',
                left: `${(window.innerWidth - state.rects.popper.width) / 2}px`,
                top: '50%',
                // transform: 'translateY(-50%)',
            }
    
            return state
        },
    }]
};  

export const noteOptions = {
    arrow: false,
    inertia: true,
    interactive: true,
    maxWidth: 550,
    moveTransition:
        'transform 0.2s ease-out',
    appendTo: document.body,
};

export const tooltipContent = {
    gracePeriod: {
        content: '<div class="text-left">The period immediately after a benefit period ends. An Employee can submit claims for the prior benefit period until the end of the Grace Period. <br/><b>Example:</b> Jan-Dec benefit period. 90-Day Grace Period. Employees have until 31 March of the following year to submit claims for the prior year.</div>',
        html: true,
    },
    rollOver: {
        content: '<div class="text-left">Any remaining Employee benefits will be added to their opening balance for the next benefit period. A maximum of 1 year is allowed.<br /> <b>Example:</b> $500 annual limit. End of Year 1, Employee has $200 remaining. In Year 2, Employee has $700 to spend ($500 annual limit + $200 rollover). If by end of Year 2 $600 remains, only $500 is allowed to be rolled over into Year 3 as $100 relates to rollover from Year 1. A maximum of 1 benefit year of rollover is allowed.</div>',
        html: true,
    },
    prorate: {
        content: '<div class="text-left">Prorate the annual limit for this benefit period (year) only</div>',
        html: true,
    },
    startDate: {
        content: "<div class='text-left'>Only claims with a service date during the Benefit Period are eligible and are deducted from the Employee's Benefit Limit. Benefit Periods are 365 days long and Benefit Limits reset each new Benefit Period.</div>",
        html: true,
    },
    adminAccess: {
        content: `<div class="text-left">Employee will have FULL Employer access:
        <li>View/Modify Company Details & Benefit Settings</li>
        <li>Manage Access Permissions</li>
        <li>View all Pending & Approved Claim Balances</li>
        <li>Manage & Send Payments to Kibono</li>
        <li>Manage Account Type, Billing Settings & Payment Sources</li>
        <li>View Invoices and Tax Statements</li>
        <li>Add/Modify Employees, Classes & Benefit Limits</li></div>`,
        html: true,
    },
    claimsAccess: {
        content: `<div class="text-left">Employee will have access to:
        <li>View all Pending & Approved Claim Balances</li>
        <li>Manage & Send Payments to Kibono</li></div>`,
        html: true,
    },
    billingAccess: {
        content: `<div class="text-left">Employee will have access to:
        <li>Manage Account Type, Billing Settings & Payment Sources</li>
        <li>View Invoices and Tax Statements</li></div>`,
        html: true,
    },
    employeesAccess: {
        content: `<div class="text-left">Employee will have access to:
        <li>Add/Modify Employees, Classes & Benefit Limits</li></div>`,
        html: true,
    }
}