/** @format */

const Statements = [
    '16 20',
    `<title>Statements</title>
    <defs>
        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="file-/-file_blank" transform="translate(-4.000000, -2.000000)">
            <g>
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="file-/-file_blank-(Background/Mask)"></g>
                <path d="M19.9039993,8.58600044 C19.8549993,8.48000044 19.7940002,8.37900022 19.7080002,8.29300022 L13.7080002,2.29299998 C13.6220002,2.20699998 13.521,2.14599988 13.415,2.09699988 C13.385,2.08299988 13.3530001,2.07499989 13.3210001,2.06399989 C13.2370001,2.03599989 13.1510003,2.01800001 13.0620003,2.01300001 C13.0400003,2.01100001 13.021,2 13,2 L6,2 C4.89699996,2 4,2.89699996 4,4 L4,20 C4,21.103 4.89699996,22 6,22 L18,22 C19.103,22 20,21.103 20,20 L20,9 C20,8.979 19.9889995,8.95999973 19.9869995,8.93799973 C19.9819995,8.84999973 19.9650003,8.7640003 19.9370003,8.68000031 C19.9260003,8.6480003 19.9169993,8.61700044 19.9039993,8.58600044 Z M14,8 L14,5.41400003 L16.5860004,8 L14,8 Z M6,4 L12,4 L12,9 C12,9.55299997 12.447,10 13,10 L18,10 L18.0020008,20 L6,20 L6,4 Z" fill="var(--ci-primary-color, currentColor)" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>`
];

export default Statements;
