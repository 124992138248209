/** @format */

import AuthState from './type';

const InitialAuthState: AuthState = {
    access: '',
    refresh: '',
};

export default InitialAuthState;
