/** @format */

export * from './services';
export * from './routes';
export * from './options';


const stripeKey = process.env.REACT_APP_STRIPE_KEY;
export const stripeConfig = {
    public_key: stripeKey || "",
    secret_key: '',
    customer: '',
};